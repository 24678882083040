<template>
  <div>
    <h1 style="color: #f0f0f0">警用无人驾驶航空器注册登记入口</h1>
    <main>
      <div>
        <p>请输入航空器ID编号或外观编码号</p>
      </div>
      <div>
        <input type="text" />
      </div>
    </main>
    <footer>
      <button @click="btn">提交</button>
      <button @click="$router.go(-1)">返回</button>
    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    btn() {
      alert("无权限");
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  text-align: center;
}
main {
  display: flex;
  margin: 50px auto;
  justify-content: center;
  > div {
    width: 400px;
    height: 70px;
    line-height: 70px;
    background-color: #c5d6d5;
    font-size: 18px;
    input {
      text-align: left;
      width: 350px;
      height: 30px;
    }
  }
}
footer {
  button {
    background-color: #667c4d;
    color: #fff;
    padding: 5px 25px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>